













import Vue from 'vue';
import Head from '@/components/Head.vue'

export default Vue.extend({
  name: 'Error',
  components : {
    Head,
  },
});
